
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useTranslation } from "react-i18next";
import { CryptoRiskInfo, FuturesRiskInfo, StockRiskInfo,CryptoFuturesRiskInfo  } from "../riskInfo";
import { useSelector } from "react-redux";
const Risk = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  if (!user) return;
  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3"></div>
          <h3 className="page-title">{t("Risk Management")}</h3>
          </div>
          <div className="white-box">
            <Tabs>
              <TabList>
                <Tab>{t("Crypto Risk Info")}</Tab>
                <Tab>{t("Crypto Futures Risk Info")}</Tab>
                <Tab>{t("FX Risk Info")}</Tab>
                <Tab>{t("Equity Risk Info")}</Tab>
              </TabList>
              <TabPanel>
                <CryptoRiskInfo targetUserId={user.id} refetch={() => true} />
              </TabPanel>
              <TabPanel>
                <CryptoFuturesRiskInfo targetUserId={user.id} refetch={() => true} />
              </TabPanel>
              <TabPanel>
                <FuturesRiskInfo targetUserId={user.id} refetch={() => true} />
              </TabPanel>
              <TabPanel>
                <StockRiskInfo targetUserId={user.id} refetch={() => true} />
              </TabPanel>
            </Tabs>
            </div>
          </div>
           
    </div>
  );
};
export default Risk;
