import React from "react";
import Loader from "../../widgets/Loader";
import { Link } from "react-router-dom";
import { baseUrl } from "../../constant/BaseUrl";
import { formatDate } from "../../utils/format";
import { useTranslation } from "react-i18next";
const url = `${baseUrl.replace("/api-admin/v1", "")}`;

const ChatRoomBox = ({ isLoading, chats, requestInfo }) => {
  console.log(requestInfo)
  const { t } = useTranslation();
  return (
    <div className="">
      {isLoading || !requestInfo ? (
        <Loader />
      ) : (
        <div className="scroll_chat">
          {requestInfo && (
            <div key={requestInfo.id} className={"incoming"}>
              <div className="bubble">
                <div>{requestInfo.issue}</div>
                {requestInfo.issueImage !== undefined &&
                  requestInfo.issueImage && (
                    <Link
                      to={
                        url + "/static/supportImages/" + requestInfo.issueImage
                      }
                      target="_blank"
                    >
                      <img
                        alt="issueImage"
                        src={
                          url +
                          "/static/supportImages/" +
                          requestInfo.issueImage
                        }
                      />
                    </Link>
                  )}
                <br />
                <span className="time-right">
                  {formatDate(requestInfo.createAt)}
                </span>
              </div>
              <br />
            </div>
          )}
          {chats.length > 0 &&
            chats.map((value) => (
              <div
                key={value.id}
                className={value.replyBy === "1" ? "outgoing" : "incoming"}
                style={
                  value.replyBy === "1"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                <div className="bubble">
                  <div>{t(value.query)}</div>
                  {value.queryImage !== undefined && value.queryImage && (
                    <Link
                      to={url + "/static/supportImages/" + value.queryImage}
                      target="_blank"
                    >
                      <img
                        alt="queryImage"
                        src={url + "/static/supportImages/" + value.queryImage}
                      />
                    </Link>
                  )}
                  <br />
                  <span className="time-left">
                    {formatDate(value.createdAt)}
                  </span>
                </div>
                <br />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ChatRoomBox;
