import {
  validateEmail,
  validatePhoneNumber,
  validateRequired,
  withFirstSpace,
  checkPassword,
  checkMinLength,
  validateAlphanumeric,
} from "./validationsFn";

export const ValidateInputFields = (name, value, required) => {
  if (value == null) {
    return "This field is required";
  }
  let requiredError = "";
  requiredError = withFirstSpace(value ? value : "");
  if (requiredError) {
    return requiredError;
  }

  if (!required) {
    return null;
  }
  requiredError = validateRequired(value);
  if (requiredError) {
    return requiredError;
  }
  if (name === "name" || name === "symbol") {
    const alphanumericError = validateAlphanumeric(value);
    if (alphanumericError) {
      return alphanumericError;
    }
    return checkMinLength(value, 1);
  }

  if (name === "email") {
    return validateEmail(value);
  }
  if (name === "phone" || name === "mobile_number" || name === "mobileNumber") {
    return validatePhoneNumber(value);
  }
  if (name === "password" || name === "newPassword") {
    return checkPassword(value);
  }
  return null;
};

export function isNegative(value) {
  if (value === "") return;
  value = Number(value);
  if (value <= 0) {
    return "Value must be greater than 0";
  }
  return;
}

export function hasMoreThanNDigits(num, n) {
  if (Math.abs(num) >= Math.pow(10, n)) {
    return `Number must be less than ${n} digits.`;
  }
  return;
}
export function exceedsLimit(num, limit) {
  if (Math.abs(num) >= limit) {
    return `Number must be less than ${limit}.`;
  }
}

export function isGreterThanMax(value, rateType = "Percentage") {
  if (rateType === "Flat") return;
  if (value === "") return;
  value = Number(value);
  if (value > 5) {
    return "Value must be less than 5 %";
  }
  return;
}

export function isNegativeOnly(value) {
  if (value === "") return;
  value = Number(value);
  if (value < 0) {
    return "Value must be positive";
  }
  return;
}
