import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, Tooltip, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";
const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const LeverageTable = ({ data, onEdit, onDelete, onDisable }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  console.log(data, "onEdit66666", user);
  const columns = useMemo(
    () => [
      {
        // accessorKey: "id",
        header: t("Serial No."),
        size: 20,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },

      {
        accessorKey: "amount",
        header: t("Leverage"),
      },

      {
        accessorKey: "type",
        header: t("Type"),
        Cell: ({ row: { original } }) => (
          <span className="text-capitalize">{original.type}</span>
        ),
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: true,
    getRowId: (row) => row.id,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        {/* <Tooltip title="Edit">
          <IconButton onClick={() => onEdit(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip> */}
        {row.original.type ==="new" ? <Tooltip title="Delete">
          <IconButton color="error" onClick={() => onDelete(row.original)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>: ""}
        <Tooltip title={t("Status")}>
          {row.original.created_by === user.id ? (
            <IconButton
              color={row.original.status === "1" ? "success" : "error"}
              onClick={() => {
                onDisable(row.original);
              }}
            >
              {row.original.status === "1" ? (
                <ToggleOnIcon />
              ) : (
                <ToggleOffIcon />
              )}
            </IconButton>
          ) : user.role === "Global Admin" ? (
            <IconButton
              color={row.original.status === "1" ? "success" : "error"}
              onClick={() => {
                onDisable(row.original);
              }}
            >
              {row.original.status === "1" ? (
                <ToggleOnIcon />
              ) : (
                <ToggleOffIcon />
              )}
            </IconButton>
          ) : (
            <IconButton>
              <LockIcon />
            </IconButton>
          )}
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          {t("Export All")}
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {t("Export Selected")}
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default LeverageTable;
