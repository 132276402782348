import React, { useState, useEffect } from "react";
import {
  ValidateInputFields,
  isNegative,
} from "../../validations/ValidateInputFields";
import Button from "../../widgets/Button";
import {
  useAddRiskInfoMutation,
  useGetRiskInfoQuery,
  useUpdateRiskInfoMutation,
} from "../../../redux/riskInfoApi";
import { handleToast } from "../../utils/handleToast";
import TableLoader from "../../widgets/TableLoader";
import { useTranslation } from "react-i18next";
const defaultState = {
  // maxTicketPerDay: { value: "", required: true, label: "Max Ticket Per Day" },
  maxSharesPerDay: { value: "", required: false, label: "Max Shares Per Day" },
  // maxLossPerDay: { value: "", required: true, label: "Max Loss Per Day" },
  maxTargetProfit: { value: "", required: false, label: "Max Target Profit" },
  maxSharesPerMonth: {
    value: "",
    required: false,
    label: "Max Shares Per Month",
  },
  maxLossPerMonth: {
    value: "",
    required: false,
    label: "Max Loss Per Month",
  },
  // maxSharesTicketPerMonth: {
  //   value: "",
  //   required: false,
  //   label: "Max Shares Ticket Per Month",
  // },
  maxSharesPerPosition: {
    value: "",
    required: false,
    label: "Max Shares Per Position",
  },
  maxOpenShares: { value: "", required: false, label: "Max Open Shares" },
  maxBpPerStock: { value: "", required: false, label: "Max Bp Per Stock" },
  stockPriceRestriction: {
    value: "",
    required: false,
    label: "Stock Price Restriction",
  },
  dailyAutoClose: { value: "", required: false, label: "Daily Auto Close" },
  balanceAutoClose: {
    value: "",
    required: false,
    label: "Stock Balance Auto Close",
  },
  noNews: { value: "N", required: true, label: "No News" },
  allowWeekendTrading: {
    value: "Y",
    required: true,
    label: "Allow Weekend Trading",
  },
  newsDisableStime: {
    value: "",
    required: false,
    label: "News Disable Start Time",
  },
  newsDisableEtime: {
    value: "",
    required: false,
    label: "News Disable End Time",
  },
};
const defaultErrorState = {
  // maxTicketPerDay: "",
  maxSharesPerDay: "",
  // maxLossPerDay: "",
  maxTargetProfit: "",
  maxSharesPerMonth: "",
  maxLossPerMonth: "",
  // maxSharesTicketPerMonth: "",
  maxSharesPerPosition: "",
  maxOpenShares: "",
  maxBpPerStock: "",
  stockPriceRestriction: "",
  dailyAutoClose: "",
  balanceAutoClose: "",
  noNews: "",
  allowWeekendTrading: "",
  newsDisableStime: "",
  newsDisableEtime: "",
};

const StockRiskInfo = ({ targetUserId, isEdit, refetch }) => {
  const { t } = useTranslation();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [stockRiskInfo, setStockRiskInfo] = useState(defaultState);
  const [stockRiskInfoError, setStockRiskInfoError] =
    useState(defaultErrorState);

  const [addRiskInfo, { isLoading: addingRiskInfo }] = useAddRiskInfoMutation();
  const [updateRiskInfo, { isLoading: updatingRiskInfo }] =
    useUpdateRiskInfoMutation();
  const {
    data,
    isLoading,
    refetch: refetchRiskInfo,
  } = useGetRiskInfoQuery({
    targetUserId,
    riskInfoType: "equity",
    userTimeZone: userTimeZone,
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const updatedStockRiskInfo = Object.keys(defaultState).reduce(
        (acc, key) => {
          let value =
          data[0][key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()];
        value = value === null || value === "null" ? "" : value;
        if (key === "newsDisableStime" || key === "newsDisableEtime") {
          value = value || "";
        }
          acc[key] = {
            ...defaultState[key],
            value: value,
          };
          return acc;
        },
        {}
      );

      setStockRiskInfo(updatedStockRiskInfo);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStockRiskInfo((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    let validationError = ValidateInputFields(
      name,
      value,
      stockRiskInfo[name].required
    );

    if (validationError) {
      setStockRiskInfoError((prevError) => {
        return { ...prevError, [name]: validationError };
      });
      return;
    }
    validationError = isNegative(value);
    setStockRiskInfoError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(stockRiskInfo).map(([key, { value }]) => [key, value])
      );

      for (let key in stockRiskInfo) {
        let validationError = ValidateInputFields(
          key,
          stockRiskInfo[key].value,
          stockRiskInfo[key].required
        );
        setStockRiskInfoError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
        validationError = isNegative(stockRiskInfo[key].value);
        if (validationError) return;
      }

      const body = {  userTimeZone: userTimeZone,targetUserId, riskInfoType: "equity", ...values };

      const mutationResult =
        data && data.length > 0
          ? await updateRiskInfo(body)
          : await addRiskInfo(body);
      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        // onClose();
        refetchRiskInfo();
      }
    };

    handleUpdateOrAdd();
  };

  if (isLoading) {
    return <TableLoader />;
  }

  return (
    <form>
      <div className="row">
      {Object.keys(defaultState).map((key) => {
          if (
            (key === "newsDisableStime" || key === "newsDisableEtime") &&
            stockRiskInfo.noNews.value !== "Y"
          ) {
            return null;
          }
          if (key === "noNews" || key === "allowWeekendTrading") {
            return (
              <div key={key} className="form-group col-md-6">
                <label className="fw-bold">{t(stockRiskInfo[key].label)}</label>
                <select
                  className="form-control"
                  name={key}
                  onChange={handleChange}
                  value={stockRiskInfo[key].value}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
                <span style={{ color: "red" }}>
                  {t(stockRiskInfoError[key])}
                </span>
              </div>
            );
          }
          return (
            <div key={key} className="form-group col-md-6">
              <label className="fw-bold">
                {t(stockRiskInfo[key].label)}
                {stockRiskInfo[key].required ? "*" : ""}
              </label>
              <input
                type={
                  key === "newsDisableStime" || key === "newsDisableEtime"
                    ? "datetime-local"
                    : "text"
                }
                className="form-control"
                placeholder={t(`Enter ${stockRiskInfo[key].label}`)}
                name={key}
                onChange={handleChange}
                value={stockRiskInfo[key].value}
              />
              <span style={{ color: "red" }}>{t(stockRiskInfoError[key])}</span>
            </div>
          );
        })}
      </div>

      <Button
        className="btn btn1"
        variant={"primary"}
        isLoading={addingRiskInfo || updatingRiskInfo}
        onClick={handleSubmit}
      >
        {isEdit ? t("Update") : t("Submit")}
      </Button>
    </form>
  );
};

export default StockRiskInfo;
