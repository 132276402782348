import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TableLoader from "../../widgets/TableLoader";
import { useGetSpreadsQuery } from "../../../redux/spreadApi";
import SpreadTable from "./SpreadTable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TableTabs = ({ handleEdit, refetchTables, handleDelete }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const [type, setType] = useState("crypto");
  const [tabIndex, setTabIndex] = useState(0);
  const {
    data: spreads,
    isLoading,
    refetch,
  } = useGetSpreadsQuery({ type, userId: user?.id });

  useEffect(() => {
    if (refetchTables) {
      refetch();
    }
  }, [refetchTables]);

  const handleTabs = (index) => {
    setTabIndex(index);
    const typeIndex = index === 0 ? "crypto" : index === 1 ? "equity" : "fx";
    setType(typeIndex);
  };

  return (
    <>
      {isLoading ? (
        <TableLoader />
      ) : (
        <SpreadTable
          data={spreads || []}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default TableTabs;
