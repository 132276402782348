import React, { useState } from "react";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { HeadWrapper } from "../Elements";
import {
  useDeleteCryptoSpreadMutation,
  useGetSpreadsQuery,
} from "../../../redux/spreadApi";
import { handleToast } from "../../utils/handleToast";
import SpreadTable from "./SpreadTable";
import SpreadForm from "./SpreadForm";
import TableLoader from "../../widgets/TableLoader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const SpreadFx = () => {
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [deleteCryptoSpread, { isLoading: deletingCryptoSpread }] =
    useDeleteCryptoSpreadMutation();
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [refetchTables, setRefetchTables] = useState(false);
  const {
    data: spreads = [],
    isLoading,
    refetch,
  } = useGetSpreadsQuery({ type: "fx", userId: user?.id });

  const handleEdit = (data, tabIndex) => {
    setEditData(data);
    setIsEdit(true);
    setTabIndex(tabIndex);
    setShowAddPopup(true);
    setRefetchTables(false);
  };

  const handleDelete = (data, tabIndex) => {
    setDeleteData(data);
    setIsDelete(true);
    setTabIndex(tabIndex);
    setShowAddPopup(true);
    setRefetchTables(false);
  };

  // =================
  const handleAddClick = () => {
    setShowAddPopup(true);
    setIsNew(true);
    setRefetchTables(false);
  };

  const handlePopupClose = (tabIndex) => {
    setShowAddPopup(false);
    setEditData(null);
    setIsEdit(false);
    setIsDelete(false);
    setIsNew(false);
    setTabIndex(tabIndex || 0);
    setRefetchTables(true);
    refetch();
  };

  const handleDeleteSubmit = async () => {
    const { id } = deleteData;
    console.log(deleteData, "deleteData");

    const result = await deleteCryptoSpread({ id });

    handleToast(t, result);
    if (result?.data?.status) {
      setRefetchTables(true);
      handlePopupClose(0);
    }
  };

  return (
    <HeadWrapper
      title="Spreads FX"
      button={
        <button className="btn btn1" onClick={handleAddClick}>
          {t("Add Spread")}
        </button>
      }
    >
      <div className="white-box">
        {isLoading ? (
          <TableLoader />
        ) : (
          <SpreadTable
            data={spreads}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        )}
        <div className="table-responsive"></div>
      </div>

      {isNew && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="900px"
          title={t("Add Spread")}
        >
          <SpreadForm handleClose={handlePopupClose} />
        </CustomModal>
      )}

      {isEdit && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="900px"
          title={t("Edit Spread")}
        >
          <SpreadForm
            isEdit={isEdit}
            editData={editData}
            isDelete={isDelete}
            handleClose={handlePopupClose}
          />
        </CustomModal>
      )}
      {isDelete && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="900px"
          title={t("Delete Spread")}
          footer={
            <Button
              className="btn btn1"
              variant={"primary"}
              isLoading={deletingCryptoSpread}
              onClick={handleDeleteSubmit}
            >
              {t("Delete")}
            </Button>
          }
        >
          <div>{t("Are you sure you want to delete?")}</div>
        </CustomModal>
      )}
    </HeadWrapper>
  );
};

export default SpreadFx;
