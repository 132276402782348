import React, { useEffect } from "react";
import { useGetUserPermissionsMutation } from "../../redux/usersApi";
import { setCredentials } from "../../redux/authSlice";
import { useGetDashboardQuery } from "../../redux/dashboardApi";
import Skeleton from "@mui/material/Skeleton";
import { useGetAdminsQuery } from "../../redux/adminApi";
import { useGetBrokersQuery } from "../../redux/brokerApi";
import { useGetIbsQuery } from "../../redux/ibApi";
import { useGetSubIbsQuery } from "../../redux/subIbApi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Dashboard() {
  const { t } = useTranslation();

  const { user, permissions } = useSelector((state) => state.auth);
  const { data: ibs, isLoading: ibsLoading } = useGetIbsQuery();
  const { data: subIbs, isLoading: subIbsLoading } = useGetSubIbsQuery();
  const { data: admins, isLoading: adminLoading } = useGetAdminsQuery(
    {},
    { skip: !permissions?.admin?.status }
  );
  const { data: brokers, isLoading: brokersLoading } = useGetBrokersQuery(
    {},
    { skip: !permissions?.broker?.status }
  );
  const { data, isLoading } = useGetDashboardQuery();
  const [getPermissions] = useGetUserPermissionsMutation();
  useEffect(() => {
    const fetchPermission = async () => {
      const userPermissions = await getPermissions().unwrap();
      setCredentials(userPermissions);
    };
    fetchPermission();
  }, []);

  return (
    <div id="layout-wrapper">
      <div className="page-wrapper">
        <div className="container-fluid">
          <h3 className="page-title">{t("Dashboard")}</h3>

          <div className="row justify-content-left">
            {permissions?.crypto?.permissions?.coins?.read && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/crypto"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-database"></i>
                    </span>
                    <h3 className="box-title">{t("Total Crypto Currency")}</h3>
                    <h4>
                      {" "}
                      {isLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter  ">{data.coins}</span>
                      )}{" "}
                    </h4>
                  </div>
                </Link>
              </div>
            )}
            {permissions?.crypto?.permissions?.coins?.read && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/crypto-pairs"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-delicious"></i>
                    </span>
                    <h3 className="box-title">{t("Total Crypto Pairs")}</h3>
                    <h4>
                      {isLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter ">{data.pairs}</span>
                      )}
                    </h4>
                  </div>
                </Link>
              </div>
            )}
            {permissions?.fx?.permissions?.listingCurrency?.read && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/fx-currency"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-delicious"></i>
                    </span>
                    <h3 className="box-title">{t("Total Fx Pairs")}</h3>
                    <h4>
                      {isLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter ">{data.fxPairs}</span>
                      )}
                    </h4>
                  </div>
                </Link>
              </div>
            )}
            {permissions?.equity?.permissions?.listingStocks?.read && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/eq-currency"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-delicious"></i>
                    </span>
                    <h3 className="box-title">{t("Total Equities")}</h3>
                    <h4>
                      {isLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter ">{data.equity}</span>
                      )}
                    </h4>
                  </div>
                </Link>
              </div>
            )}
            {permissions?.support?.status && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/support"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-headphones"></i>
                    </span>
                    <h3 className="box-title">{t("Support")}</h3>
                    <h4>
                      {isLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter ">{data.support}</span>
                      )}
                    </h4>
                  </div>
                </Link>
              </div>
            )}
            {permissions?.admin?.status && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/admins"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-user"></i>
                    </span>
                    <h3 className="box-title">{t("Total Admins")}</h3>
                    <h4>
                      {adminLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter ">{admins?.length}</span>
                      )}
                    </h4>
                  </div>
                </Link>
              </div>
            )}
            {permissions?.broker?.status && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/brokers"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-users"></i>
                    </span>
                    <h3 className="box-title">{t("Total Brokers")}</h3>
                    <h4>
                      {brokersLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter ">{brokers?.length}</span>
                      )}
                    </h4>
                  </div>
                </Link>
              </div>
            )}
            {permissions?.ib?.status && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/ibs"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-user"></i>
                    </span>
                    <h3 className="box-title">{t("Introducing Brokers")}</h3>
                    <h4>
                      {ibsLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter ">{ibs?.length}</span>
                      )}
                    </h4>
                  </div>
                </Link>
              </div>
            )}

            {permissions?.ib?.permissions?.subIb?.read && (
              <div className="col-lg-3 col-md-4">
                <Link to={"/subIbs"}>
                  <div className="da-box analytics-info">
                    <span className="d_icon">
                      <i className="fa fa-usd"></i>
                    </span>
                    <h3 className="box-title">{t("Total Customers")}</h3>
                    <h4>
                      {subIbsLoading ? (
                        <Skeleton width={210} height={35}></Skeleton>
                      ) : (
                        <span className="counter">{subIbs?.length}</span>
                      )}
                    </h4>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
        {/* <footer className="footer text-center"> 2024 © EBSWare</footer> */}
      </div>
    </div>
  );
}
export default Dashboard;
