import { apiSlice } from "./api";
import {
  postLeverageApi,
  getLeverageApi,
  leverageStatusApi,
  getLeveragesPermissionsApi,
  leveragePermissionsApi,
  leverageDeleteApi
} from "../components/constant/Api";

export const leverageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLeverage: builder.mutation({
      query: (post) => ({
        url: postLeverageApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["leverage"],
    }),
    leverageStatus: builder.mutation({
      query: (post) => ({
        url: leverageStatusApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["leverage"],
    }),

    getLeverage: builder.query({
      query: ({ userId }) => ({
        url: `${getLeverageApi}/${userId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getLeveragesPermissions: builder.query({
      query: ({ userId }) => ({
        url: `${getLeveragesPermissionsApi}/${userId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    leveragePermission: builder.mutation({
      query: (post) => ({
        url: leveragePermissionsApi,
        method: "POST",
        body: post,
      }),
    }),
    leverageDelete: builder.mutation({
      query: (post) => ({
        url: leverageDeleteApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["leverage"],
    }),
  }),
});

export const {
  useAddLeverageMutation,
  useGetLeverageQuery,
  useLeverageStatusMutation,
  useGetLeveragesPermissionsQuery,
  useLeveragePermissionMutation,
  useLeverageDeleteMutation,
} = leverageApi;
