import { useMemo } from "react";
import { MRT_Table, useMaterialReactTable } from "material-react-table";
import { MenuItem, Select } from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const validateRequired = (value) => !!value.length;

const CryptoCommissionTable = ({ data, onEdit }) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t("S.N"),
        enableEditing: false,
        size: 80,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        accessorKey: "symbol",
        header: t("Name"),
        enableEditing: false,
      },
      {
        accessorKey: "buy_commission",
        header: t("Buy Commission"),
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "text",
          required: true,
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? "Required"
              : undefined;
            if (Number(event.currentTarget.value) < 0) {
              toast.dismiss();
              toast.error("Value must be positive or zero");
              return;
            }
            if (validationError) {
              toast.dismiss();
              toast.error(validationError);
              return;
            }
            onEdit(
              {
                ...row.original,
                buy_commission: event.currentTarget.value,
              },
              "buy_commission"
            );
          },
        }),
      },
      {
        accessorKey: "sell_commission",
        header: t("Sell Commission"),
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "text",
          required: true,
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? "Required"
              : undefined;
            if (Number(event.currentTarget.value) < 0) {
              toast.dismiss();
              toast.error("Value must be positive or zero");
              return;
            }
            if (validationError) {
              toast.dismiss();
              toast.error(validationError);
              return;
            }
            onEdit(
              {
                ...row.original,
                sell_commission: event.currentTarget.value,
              },
              "sell_commission"
            );
          },
        }),
      },
      {
        accessorKey: "type",
        header: t("Rate Type"),
        enableEditing: true,
        editVariant: "select",
        Cell: ({ cell, row }) => (
          <select
            value={row.original.type || ""}
            onChange={(event) => {
              onEdit(
                {
                  ...row.original,
                  type: event.target.value,
                },
                "type"
              );
            }}
            fullWidth
          >
            <option value="Flat">Flat</option>
            <option value="Percentage">Percentage</option>
          </select>
        ),
      },
    ],
    [data]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    editDisplayMode: "cell",
    enableEditing: true,
    getRowId: (row) => row.id,
  });

  return <MRT_Table table={table} />;
};

export default CryptoCommissionTable;
