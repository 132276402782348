import { useState, useEffect } from "react";
import { useGetAdminsQuery } from "../redux/adminApi";
import { useGetBrokersQuery } from "../redux/brokerApi";
import { useGetIbsQuery } from "../redux/ibApi";
import { useSelector } from "react-redux";

const useUserHierarchy = () => {
  const {
    user: { role: userType, id: userId },
  } = useSelector((state) => state.auth);

  // Fetch all data
  const {
    data: admins,
    isSuccess: gotAdmins,
    isFetching: fetchingAdmins,
  } = useGetAdminsQuery();
  const {
    data: brokers,
    isSuccess: gotBrokers,
    isFetching: fetchingBrokers,
  } = useGetBrokersQuery();
  const {
    data: ibs,
    isSuccess: gotIbs,
    isFetching: fetchingIbs,
  } = useGetIbsQuery();

  // State to store final hierarchy data
  const [currantAdmins, setCurrantAdmins] = useState([]);
  const [currantBrokers, setCurrantBrokers] = useState([]);
  const [currantIbs, setCurrantIbs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(gotAdmins, gotBrokers, gotIbs, "*****()", admins, brokers, ibs);
    if (gotAdmins || gotBrokers || gotIbs) {
      setCurrantAdmins(admins);
      setCurrantBrokers(brokers);
      setCurrantIbs(ibs);
      setIsLoading(false); // Data is fully loaded
    }
  }, [gotAdmins, gotBrokers, gotIbs, admins, brokers, ibs]);

  // Wait until all data is received
  if (fetchingAdmins || fetchingBrokers || fetchingIbs) {
    return {
      isLoading: true,
      currantAdmins: currantAdmins || [],
      currantBrokers: currantBrokers || [],
      currantIbs: currantIbs || [],
    };
  }

  return { isLoading, currantAdmins, currantBrokers, currantIbs };
};

export default useUserHierarchy;
