import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, Tooltip, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";
import CopyToClipboard from "../../widgets/CopyToClipboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DeleteIcon from "@mui/icons-material/Delete";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useTranslation } from "react-i18next";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const UsersTable = ({
  data,
  onEdit,
  onStatus,
  onPermission,
  access,
  handleUserInfo,
  addBalance,
  onDelete,
  onTwoFactor,
  userType,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t("Serial No."),
        enableEditing: false,
        size: 80,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        accessorKey: "user_name",
        enableGlobalFilter: true,
        header: t("Username"),
        Cell: ({ row, item }) => (
          <span>{row.original.user_name ? row.original.user_name : "-"}</span>
        ),
      },
      ...(userType === "SubIB"
        ? []
        : [
            {
              accessorKey: "refer_id",
              header: t("Refer Code"),
              enableGlobalFilter: true,
              Cell: ({ row }) => (
                <span style={{ display: "flex", gap: "4px" }}>
                  {row.original.refer_id ? row.original.refer_id : "-"}{" "}
                  <CopyToClipboard text={row.original.refer_id} />
                </span>
              ),
            },
          ]),

      {
        accessorKey: "name",
        header: t("Name"),
        enableGlobalFilter: true,
        Cell: ({ row, item }) => (
          <span>{row.original.name ? row.original.name : "-"}</span>
        ),
      },
      // ...(userType === "SubIB" && {
      //   accessorKey: "refred_by",
      //   header: t("Refred by"),
      //   enableGlobalFilter: true,
      //   Cell: ({ row, item }) => (
      //     <span>{row.original.refred_by ? row.original.refred_by : "-"}</span>
      //   ),
      // }),
      ...(userType
        ? [
            {
              accessorKey: "refred_by",
              header: t("Refred by"),
              enableGlobalFilter: true,
              Cell: ({ row, item }) => (
                <span>
                  {row.original.refred_by ? row.original.refred_by : "-"}
                </span>
              ),
            },
          ]
        : []),
      {
        accessorKey: "email",
        header: t("Email"),
        enableGlobalFilter: true,
        Cell: ({ row, item }) => (
          <span>{row.original.email ? row.original.email : "-"}</span>
        ),
      },
      {
        accessorKey: "mobile_number",
        header: t("Phone"),
        enableGlobalFilter: true,
        Cell: ({ row, item }) => (
          <span>
            {row.original.mobile_number ? row.original.mobile_number : "-"}
          </span>
        ),
      },
      {
        accessorKey: "createdAt",
        header: t("Date"),
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.createdAt)}</span>,
      },

      // ...(onPermission
      //   ? [
      //       {
      //         accessorKey: "actions",
      //         header: "Permissions",
      //         Cell: ({ row }) => (
      //           <Tooltip title="Add Permission">
      //             <button
      //               className="btn btn1"
      //               color="error"
      //               onClick={() => onPermission(row.original)}
      //             >
      //               <span>Add Permissions</span>
      //             </button>
      //           </Tooltip>
      //         ),
      //       },
      //     ]
      //   : []),
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: access?.read ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: access?.write,
    getRowId: (row) => row.id,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip
          title={t("Edit")}
          disabled={row.original.user_lock === "1" ? false : true}
        >
          <IconButton onClick={() => onEdit(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {addBalance && (
          <Tooltip
            title={t("Add Balance")}
            disabled={row.original.user_lock === "1" ? false : true}
          >
            <IconButton onClick={() => addBalance(row.original)}>
              <AttachMoneyIcon />
            </IconButton>
          </Tooltip>
        )}
        {handleUserInfo && (
          <Tooltip
            title={t("User Info")}
            disabled={row.original.user_lock === "1" ? false : true}
          >
            <IconButton onClick={() => handleUserInfo(row.original)}>
              <AccountBalanceIcon />
            </IconButton>
          </Tooltip>
        )}
        {access.delete && (
          <Tooltip title={t("Status")}>
            <IconButton
              color={row.original.user_lock === "1" ? "success" : "error"}
              onClick={() => onStatus(row.original)}
            >
              {row.original.user_lock === "1" ? (
                <ToggleOnIcon />
              ) : (
                <ToggleOffIcon />
              )}
            </IconButton>
          </Tooltip>
        )}
        {onTwoFactor && access.delete && (
          <Tooltip title={t("2FA - authentification")}>
            <IconButton
              disabled={row.original.user_lock === "1" ? false : true}
              color={row.original.enable_2fa === "Y" ? "success" : "primary"}
              onClick={() => onTwoFactor(row.original)}
            >
              {row.original.enable_2fa === "N" ? (
                <LockOpenIcon />
              ) : (
                <LockPersonIcon />
              )}
            </IconButton>
          </Tooltip>
        )}
        {onDelete && access.delete && (
          <Tooltip title={t("Delete")}>
            <IconButton
              disabled={row.original.user_lock === "1" ? false : true}
              color={row.original.user_lock === "1" ? "success" : "error"}
              onClick={() => onDelete(row.original)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          {t("Export All")}
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {t("Export Selected")}
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
