import { apiSlice } from "./api";
import {
  getSpreadsApi,
  getCryptoSpreadsApi,
  postCryptoSpreadsApi,
  updateCryptoSpreadsApi,
  deleteCryptoSpreadsApi,
  bulkSpread,
} from "../components/constant/Api";

export const commissionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpreads: builder.query({
      query: ({ type, userId }) => ({
        url: `${getSpreadsApi}/${type}/${userId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getCryptoSpreads: builder.query({
      query: ({ type }) => ({
        url: `${getCryptoSpreadsApi}/${type}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    addCryptoSpread: builder.mutation({
      query: (post) => ({
        url: postCryptoSpreadsApi,
        method: "POST",
        body: post,
      }),
    }),
    addBulkSpread: builder.mutation({
      query: (post) => ({
        url: bulkSpread,
        method: "POST",
        body: post,
      }),
    }),
    updateCryptoSpread: builder.mutation({
      query: (post) => ({
        url: updateCryptoSpreadsApi,
        method: "POST",
        body: post,
      }),
    }),
    deleteCryptoSpread: builder.mutation({
      query: (post) => ({
        url: deleteCryptoSpreadsApi,
        method: "POST",
        body: post,
      }),
    }),
  }),
});

export const {
  useAddCryptoSpreadMutation,
  useGetCryptoSpreadsQuery,
  useGetSpreadsQuery,
  useUpdateCryptoSpreadMutation,
  useDeleteCryptoSpreadMutation,
  useAddBulkSpreadMutation,
} = commissionApi;
