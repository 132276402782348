import React, { useEffect, useState } from "react";
import {
  useAddCommissionBulkMutation,
  useGetPairsCommissionQuery,
  useGetCommissionQuery,
  useGetCommissionFxQuery,
  useGetPairsCommissionFxQuery,
  useAddCommissionFxBulkMutation,
} from "../../../redux/commissionApi";
import FuturesCommissionTable from "./FuturesCommissionTable";
import TableLoader from "../../widgets/TableLoader";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import toast from "react-hot-toast";
import { useGetAssetPairsQuery } from "../../../redux/usersApi";
import { useTranslation } from "react-i18next";

const FuturesCommission = ({
  targetUserId,
  isEdit,
  isNew,
  refetch,
  onClose,
  editData,
  type,
}) => {
  const { t } = useTranslation();
  const {
    data: userCommissions = [],
    isLoading: commissionLoading,
    refetch: refetchUserCommissions,
    isFetching,
  } = useGetCommissionFxQuery({
    userId: targetUserId,
  });

  const [addCommissionBulk, { isLoading: addingCommission }] =
    useAddCommissionFxBulkMutation();

  const [cryptoCoinRates2, setCryptoCoinRates2] = useState([]);

  const [commissionRate, setCommissionRate] = useState({
    sell: { value: "", required: true },
    buy: { value: "", required: true },
  });

  const { data: cryptoPairs, isLoading: loadingCryptoCoins } =
    useGetPairsCommissionFxQuery({
      userId: targetUserId,
      type,
    });

  console.log(cryptoPairs, "cryptoPairs");

  const [commissionBuyError, setCommissionBuyError] = useState();
  const [commissionSellError, setCommissionSellError] = useState();

  useEffect(() => {
    if (userCommissions.length > 0 && cryptoPairs) {
      const userCommMap = new Map();
      userCommissions.forEach((item) => {
        userCommMap.set(`${item.symbol}`, {
          id: item.pairId,
          symbol: item.symbol,
          buy_commission: item.buy_commission ? item.buy_commission : 0,
          sell_commission: item.sell_commission ? item.sell_commission : 0,
          type: item.type,
        });
      });

      const mergedArray = [];

      cryptoPairs.forEach((item) => {
        const key = `${item.symbol}`;

        if (userCommMap.has(key)) {
          mergedArray.push(userCommMap.get(key));
        } else {
          mergedArray.push({
            id: item.id,
            symbol: item.symbol,
            buy_commission: 0,
            sell_commission: 0,
            type: "Flat",
          });
        }
      });

      setCryptoCoinRates2(mergedArray);
    } else if (cryptoPairs && userCommissions && userCommissions.length === 0) {
      const mergedArray = [];

      cryptoPairs.forEach((item) => {
        mergedArray.push({
          id: item.id,
          symbol: item.symbol,
          buy_commission: 0,
          sell_commission: 0,
          type: "Flat",
        });
      });

      setCryptoCoinRates2(mergedArray);
    }
  }, [cryptoPairs, userCommissions]);

  // useEffect(() => {
  //   if (userCommissions.length > 0 && cryptoPairs && pairsData) {
  //     let userComs = userCommissions.filter((obj1) =>
  //       pairsData.some((obj2) => obj2.pair_id === obj1.pairId)
  //     );

  //     let result = cryptoPairs.filter(
  //       (obj1) => !userCommissions.some((obj2) => obj2.pairId === obj1.id)
  //     );

  //     result = result.filter((obj1) =>
  //       pairsData.some((obj2) => obj2.pair_id === obj1.id)
  //     );

  //     const all = [...userComs, ...result];

  //     const cryptoCoinRates = all?.map((coin) => {
  //       return {
  //         ...coin,
  //         id: coin.pairId || coin.id,
  //         buy_commission: coin.buy_commission ? coin.buy_commission : 0,
  //         sell_commission: coin.sell_commission ? coin.sell_commission : 0,
  //       };
  //     });

  //     setCryptoCoinRates2(cryptoCoinRates);
  //   } else if (
  //     cryptoPairs &&
  //     userCommissions &&
  //     pairsData &&
  //     userCommissions.length === 0
  //   ) {
  //     console.log(cryptoPairs, "cryptoPairs", pairsData);

  //     const result = cryptoPairs.filter((obj1) =>
  //       pairsData.some((obj2) => obj2.pair_id === obj1.id)
  //     );
  //     console.log(result, "jkkkkkk");

  //     const cryptoCoinRates = result?.map((coin) => {
  //       return {
  //         ...coin,
  //         buy_commission: 0,
  //         sell_commission: 0,
  //       };
  //     });
  //     setCryptoCoinRates2(cryptoCoinRates);
  //   }
  // }, [cryptoPairs, userCommissions, pairsData]);

  const handleForAll = (type) => {
    const validationError = ValidateInputFields(
      type,
      commissionRate[type].value,
      commissionRate[type].required
    );

    if (validationError && type === "buy") {
      setCommissionBuyError(validationError);
      return;
    }
    if (Number(commissionRate[type].value) < 0 && type === "buy") {
      setCommissionBuyError("Rate must be positive or zero");
      return;
    }
    if (Number(commissionRate[type].value) > 30 && type === "buy") {
      setCommissionBuyError("Rate must be 30% or below");
      return;
    }

    if (validationError && type === "sell") {
      setCommissionSellError(validationError);
      return;
    }
    if (Number(commissionRate[type].value) < 0 && type === "sell") {
      setCommissionSellError("Rate must be positive or zero");
      return;
    }
    if (Number(commissionRate[type].value) > 30 && type === "sell") {
      setCommissionSellError("Rate must be 30% or below");
      return;
    }

    if (userCommissions.length > 0) {
      const cryptoCoinRates = cryptoCoinRates2?.map((coin) => {
        return {
          ...coin,
          id: coin.pairId || coin.id,
          [`${type}_commission`]: commissionRate[type].value,
        };
      });

      setCryptoCoinRates2(cryptoCoinRates);
    } else if (cryptoPairs && userCommissions && userCommissions.length === 0) {
      const cryptoCoinRates = cryptoCoinRates2?.map((coin) => {
        return {
          ...coin,
          [`${type}_commission`]: commissionRate[type].value,
        };
      });
      setCryptoCoinRates2(cryptoCoinRates);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const commissionBuyRates = cryptoCoinRates2
        .map((coin) => Number(coin.buy_commission))
        .every((rate) => rate > -1);

      const commissionSellRates = cryptoCoinRates2
        .map((coin) => Number(coin.sell_commission))
        .every((rate) => rate > -1);

      if (!commissionBuyRates || !commissionSellRates) {
        toast.dismiss();
        toast.error("Commission rates must be positive or zero");
        return;
      }

      const notZeros = cryptoCoinRates2.filter(
        (item) =>
          Number(item.sell_commission) !== 0 && item.buy_commission !== 0
      );

      const mutationResult = await addCommissionBulk({
        cryptosRates: notZeros,
        userId: targetUserId,
      });

      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        refetchUserCommissions();
      }
    };

    handleUpdateOrAdd();
  };

  const handleEditCommissionRates = (data, type) => {
    setCryptoCoinRates2((prevCryptoData) =>
      prevCryptoData.map((crypto) =>
        crypto.id === data.id ? { ...crypto, [type]: data[type] } : crypto
      )
    );
  };

  return (
    <div>
      {loadingCryptoCoins || commissionLoading ? (
        <TableLoader />
      ) : (
        <>
          <div className="row align-items-end pt-2 pb-2">
            <div className="col-12 ">
              <div className="form-group mb-md-0">
                <label className="fw-bold">{t("Enter Buy and Sell")}</label>
                <div className="row">
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder={t("Enter Buy Rate")}
                      name="commissionRate"
                      onChange={(e) => {
                        setCommissionRate((prev) => {
                          return {
                            ...prev,
                            buy: {
                              ...prev["buy"],
                              value: e.target.value,
                            },
                          };
                        });

                        const validationError = ValidateInputFields(
                          "buy",
                          e.target.value,
                          commissionRate["buy"].required
                        );
                        if (validationError) {
                          setCommissionBuyError(validationError);
                          return;
                        }
                        if (Number(e.target.value) < 0) {
                          setCommissionBuyError(
                            "Rate must be positive or zero"
                          );
                          return;
                        }
                        if (Number(e.target.value) > 30) {
                          setCommissionBuyError("Rate must be 30% or below");
                          return;
                        }
                        setCommissionBuyError("");
                      }}
                      value={commissionRate.buy?.value}
                    />
                    <span style={{ color: "red" }}>
                      {t(commissionBuyError)}
                    </span>
                    <div
                      className="col-6 mt-2"
                      onClick={() => handleForAll("buy")}
                    >
                      <div></div>
                      <Button className="btn btn1" variant="primary">
                        {t("Set Buy")}
                      </Button>
                    </div>
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder={t("Enter Sell Rate")}
                      name="commissionRate"
                      onChange={(e) => {
                        setCommissionRate((prev) => {
                          return {
                            ...prev,
                            sell: {
                              ...prev["sell"],
                              value: e.target.value,
                            },
                          };
                        });

                        const validationError = ValidateInputFields(
                          "sell",
                          e.target.value,
                          commissionRate["sell"].required
                        );
                        if (validationError) {
                          setCommissionSellError(validationError);
                          return;
                        }
                        if (Number(e.target.value) < 0) {
                          setCommissionSellError(
                            "Rate must be positive or zero"
                          );
                          return;
                        }
                        if (Number(e.target.value) > 30) {
                          setCommissionBuyError("Rate must be 30% or below");
                          return;
                        }
                        setCommissionSellError("");
                      }}
                      value={commissionRate.sell?.value}
                    />
                    <span style={{ color: "red" }}>
                      {t(commissionSellError)}
                    </span>
                    <div
                      className="col-6 mt-2"
                      onClick={() => handleForAll("sell")}
                    >
                      <div></div>
                      <Button className="btn btn1" variant="primary">
                        {t("Set Sell")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FuturesCommissionTable
            data={cryptoCoinRates2}
            onEdit={handleEditCommissionRates}
          />
          {cryptoCoinRates2 && cryptoCoinRates2.length > 0 && (
            <Button
              className="btn btn1 mt-2"
              variant={"primary"}
              isLoading={addingCommission}
              onClick={handleSubmit}
            >
              {t("Save")}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default FuturesCommission;
