import React, { useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import LeverageTable from "./LeverageTable";
import {
  ValidateInputFields,
  exceedsLimit,
  isNegative,
} from "../../validations/ValidateInputFields";
import { handleToast } from "../../utils/handleToast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { useSelector } from "react-redux";
import {
  useAddLeverageMutation,
  useGetLeverageQuery,
  useLeverageStatusMutation,
  useLeverageDeleteMutation
} from "../../../redux/leverageApi";
import { useTranslation } from "react-i18next";
const Leverage = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const {
    data: records = [],
    isLoading,
    refetch,
  } = useGetLeverageQuery({ userId: user.id });
  const [addLeverage, { isLoading: addingLeverage }] = useAddLeverageMutation();
  const [deleteLeverage, {isLoading:deleteLeaverage }] = useLeverageDeleteMutation();

  const [statusLeverage, { isLoading: statusUpdating }] =
    useLeverageStatusMutation();

  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [disableData, setDisableData] = useState(null);

  const [disabledStatus, setDisabledStatus] = useState(0);
  const [show, setShow] = useState(false);
  const [leverage, setLeverage] = useState({
    amount: { value: "", required: true },
    type: { value: "", required: true },
  });
  const [leverageError, setLeverageError] = useState({
    amount: "",
    type: "",
  });

  const handleClose = () => {
    setLeverage({
      amount: { value: "", required: true },
      type: { value: "", required: true },
    });
    setLeverageError(null);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
    setIsDisable(false);
  };
  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    setLeverage({
      name: { value: data.name, required: true },
      type: { value: data.type, required: true },
    });
    handleShow();
  };

  const handleDelete = async(data) => {
      setDeleteData(data);
     setIsDelete(true);
      handleShow();
    
  };

  const handleDisable = (data) => {
    setDisableData(data);
    setDisabledStatus(data.status);
    setIsDisable(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const numericValue = isNaN(value) ? value : value;

    setLeverage((prev) => ({
      ...prev,
      [name]: { ...prev[name], value: numericValue },
    }));

    const isRequired = leverage[name]?.required || false;

    let validationErrors = [];

    const requiredValidation = ValidateInputFields(
      name,
      numericValue,
      isRequired
    );
    if (requiredValidation) validationErrors.push(requiredValidation);

    const negativeValidation = isNegative(numericValue);
    if (negativeValidation) validationErrors.push(negativeValidation);

    const digitValidation = exceedsLimit(numericValue, 1000);
    if (digitValidation) validationErrors.push(digitValidation);

    setLeverageError((prevError) => ({
      ...prevError,
      [name]: validationErrors.length ? validationErrors.join(", ") : null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleDelete = async () => {

        if (!deleteData) return;
        const response = await deleteLeverage(deleteData);  
          handleToast(t, response);
          if(response?.data.status){
            refetch();
          handleClose();

          }
          
    };

    const handleDisable = async () => {
      const { id, status } = disableData;
      const result = await statusLeverage({ id, status });

      handleToast(t, result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(leverage).map(([key, { value }]) => [key, value])
      );

      let errors = {};
      let hasError = false;

      for (let key in leverage) {
        let validationErrors = [];

        const requiredValidation = ValidateInputFields(
          key,
          leverage[key].value,
          leverage[key].required
        );
        if (requiredValidation) validationErrors.push(requiredValidation);

        const negativeValidation = isNegative(leverage[key].value);
        if (negativeValidation) validationErrors.push(negativeValidation);

        const digitValidation = exceedsLimit(leverage[key].value, 1000);
        if (digitValidation) validationErrors.push(digitValidation);

        if (validationErrors.length) {
          errors[key] = validationErrors.join(", ");
          hasError = true;
        }
      }

      setLeverageError(errors);

      if (hasError) return false;

      const mutationResult = await addLeverage(values);

      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    isDelete
      ? handleDelete()
      : isDisable
      ? handleDisable()
      : handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Leverage")}</h3>
            <div className=" ms-auto">
              <button
                disabled={addingLeverage}
                className="btn btn1"
                onClick={handleShow}
              >
                {t("Add Leverage")}
              </button>
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <LeverageTable
                  data={records}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onDisable={handleDisable}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={
          isDelete
            ? t("Delete Leverage")
            : isDisable && disabledStatus === "0"
            ? t("Enable Leverage")
            : isDisable && disabledStatus === "1"
            ? t("Disable Leverage")
            : isEdit
            ? t("Edit Leverage")
            : t("Add Leverage")
        }
        footer={
          <Button
            className="btn btn1"
            variant={isDelete ? "danger" : "primary"}
            isLoading={ isDelete? deleteLeaverage :isDisable ? statusUpdating : addingLeverage}
            onClick={handleSubmit}
          >
            {isDelete
              ? t("Delete")
              : isDisable && disabledStatus === "0"
              ? t("Enable")
              : isDisable && disabledStatus === "1"
              ? t("Disable")
              : isEdit
              ? t("Update")
              : t("Submit")}
          </Button>
        }
      >
        {isDelete ? (
          <div>{t("Are you sure you want to delete?")}</div>
        ) : isDisable ? (
          <div>
            {t(
              `Are you sure you want to ${
                disabledStatus === "0" ? "enable" : "disable"
              }?`
            )}
          </div>
        ) : (
          <form>
            <div className="form-group">
              <label className="fw-bold">{t("Leverage Amount")}</label>
              <input
                type="number"
                className="form-control"
                placeholder={t("Enter Amount")}
                name="amount"
                onChange={handleChange}
                value={leverage.amount.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>{t(leverageError?.amount)}</span>
            </div>
            <div className="form-group">
              <label className="fw-bold">{t("Type")}</label>
              <select
                className="form-select shadow-none  "
                id="type"
                name="type"
                value={leverage.type?.value}
                onChange={handleChange}
                disabled={isEdit}
              >
                <option value="">{t("Select Type")}</option>
                {user?.role === "Global Admin" && (
                  <option value="default">{t("Default")}</option>
                )}
                <option value="new">{t("New")}</option>
              </select>
              <span style={{ color: "red" }}>{t(leverageError?.type)}</span>
            </div>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default Leverage;
